import React, {Component} from "react"
import {observer} from "mobx-react"

import {Model} from "../../Model"
import {Preloader} from "./parts/Preloader"
import {route} from "lib/functions/route"
import {SignIn} from "pages/sign-in"
import {SignUp} from "pages/sign-up"
import {App} from "../../App"
import {Authorisation} from "models/Authorisation"
import {Gate} from "lib/gate/Gate"
// import {Maintenance} from "Maintenance"

interface Props {
    gate: Gate
    model: Model
    auth: Authorisation
}

@observer
export class AuthLayer extends Component<Props, {}> {
    //todo: uncomment the components to enable the page
    public render() {
        const {gate, model, auth} = this.props
        const path = model.router.path

        if (auth.status === "loading") {
            return <Preloader />
        }

        if (route("/sign-in(/)", path)) {
            // return <Maintenance />
            return <SignIn model={model} auth={auth} />
        }

        if (route("/sign-up(/)", path)) {
            // return <Maintenance />
            return <SignUp auth={auth} model={model} />
        }
        // return <Maintenance />
        return <App gate={gate} model={model} />
    }
}
